'use strict'

import { isMobile } from 'assets/core/js/common'
import tooltipV2 from '@ui/Tooltip/component'
import ImagesSlider from '@ui/ImagesSlider/component'
import Flags from '@ui/Flags/component'
import BookingReward from '@ui/Accommodation/Offer/BookingReward/component'

import FavoriteButton from './plugin.favorite'

import type { Options as FavoriteButtonOptions } from './plugin.favorite'

export interface Options {
  onOpenLink?: (card: HTMLElement) => void
  onClickViewBtn?: (card: HTMLElement, button: HTMLButtonElement) => void
  onClickViewOnMapBtn?: (card: HTMLElement, button: HTMLButtonElement) => void
  pluginsConfig?: {
    favorite?: FavoriteButtonOptions
  }
}

const preventOpenClasses = [
  'product-card__location',
  'slider-navigation',
  'slider-pagination',
  'product-card__view-map',
  'product-card__award',
  'product-card__flag',
  'dca-tooltip-v2',
  'product-card__favorite',
  'product-card__offer-price-details',
]

const openLink = function (url: string | null, card: HTMLElement, onOpenLink: Options['onOpenLink'] | null): void {
  if (url) {
    if (!isMobile()) {
      window.open(url)
    } else {
      document.location.href = url
    }
    if (onOpenLink) {
      onOpenLink(card)
    }
  }
}

const initClickViewBtn = function (card: HTMLElement, fn?: Options['onClickViewBtn']): void {
  const buttonEl = card.querySelector<HTMLButtonElement>('.product-card__actions button')

  if (!buttonEl || !fn) {
    return
  }

  buttonEl.addEventListener('click', (e) => {
    e.stopPropagation()
    fn(card, buttonEl)
  })
}

const initClickViewOnMapBtn = function (card: HTMLElement, fn?: Options['onClickViewOnMapBtn']): void {
  const buttonEl = card.querySelector<HTMLButtonElement>('.product-card__view-map')

  if (!buttonEl) {
    return
  }

  buttonEl.addEventListener('click', (e) => {
    e.stopPropagation()
    e.preventDefault()

    fn && fn(card, buttonEl)
  })
}

const initializeOpenLink = function (card: HTMLElement, onOpenLink: Options['onOpenLink'] | null): void {
  card.addEventListener('click', function (this: HTMLElement, e) {
    const target = e.target as HTMLElement

    if (preventOpenClasses.filter((val) => target.classList.contains(val) || target.closest(`.${val}`) !== null).length === 0) {
      e.preventDefault()
      e.stopPropagation()

      const link = card.querySelector<HTMLLinkElement>('.product-card__link')

      if (!link) {
        return
      }

      openLink(link.href, card, onOpenLink)
    }
  })
}

const initTooltips = function (rootEl: HTMLElement): void {
  const awardEl = rootEl.querySelector<HTMLElement>('.product-card__award')
  const awardTooltipEl = rootEl.querySelector<HTMLElement>('.product-card__award-tooltip')

  if (awardEl && awardTooltipEl) {
    tooltipV2(awardEl, awardTooltipEl)
  }

  const flagEl = rootEl.querySelector<HTMLElement>('.product-card__flag')
  const flagTooltipEl = rootEl.querySelector<HTMLElement>('.product-card__flag-tooltip')

  if (flagEl && flagTooltipEl) {
    tooltipV2(flagEl, flagTooltipEl)
  }

  const offerDiscountInfoEl = rootEl.querySelector<HTMLElement>('.product-card__offer-discount-info-text')
  const offerDiscountInfoTooltipEl = rootEl.querySelector<HTMLElement>('.product-card__offer-discount-info-tooltip')

  if (offerDiscountInfoEl && offerDiscountInfoTooltipEl) {
    tooltipV2(offerDiscountInfoEl, offerDiscountInfoTooltipEl)
  }

  Flags(rootEl)
}

const initImagesSlider = function (card: HTMLElement): void {
  const imagesSliderEl = card.querySelector<HTMLElement>('.product-card__images .images-slider')

  if (!imagesSliderEl) {
    return
  }

  ImagesSlider(imagesSliderEl)
}

const initPlugins = function (card: HTMLElement, pluginsConfig: Options['pluginsConfig']): void {
  if (!pluginsConfig) {
    pluginsConfig = {}
  }

  // if the favorite button is in the markup but has no specific config, initialize the plugin anyway
  if (!pluginsConfig.favorite && card.querySelector<HTMLElement>('.product-card__favorite')) {
    pluginsConfig.favorite = {}
  }

  Object.keys(pluginsConfig).forEach((key) => {
    switch (key) {
      case 'favorite':
        FavoriteButton(card, pluginsConfig?.[key] as FavoriteButtonOptions)
        break
    }
  })
}

export default function (el: HTMLElement, options: Options = {}): void {
  if (el.hasAttribute('data-init')) {
    return
  }

  initializeOpenLink(el, options.onOpenLink || null)
  initClickViewBtn(el, options.onClickViewBtn)
  initPlugins(el, options.pluginsConfig ?? {})

  el.setAttribute('data-init', 'true')

  // defer the following non-critical calls
  setTimeout(() => {
    initImagesSlider(el)
    initTooltips(el)
    initClickViewOnMapBtn(el, options.onClickViewOnMapBtn)

    const bookingRewardElement = el.querySelector<HTMLElement>('.accommodation-offer-booking-reward')
    if (bookingRewardElement) {
      BookingReward(bookingRewardElement)
    }
  }, 0)
}
