'use strict'

import Slider from '@ui/Slider/component'

export default (el: HTMLElement): void => {
  if (!el) {
    return
  }

  const sliderEl = el.querySelector<HTMLElement>('.slider')

  if (!sliderEl) {
    return
  }

  const totalSlides = sliderEl.querySelectorAll<HTMLElement>('.slide')

  if (totalSlides.length === 0) {
    return
  }

  Slider(
    sliderEl,
    el.querySelector<HTMLElement>('.slider-pagination'),
    el.querySelector<HTMLElement>('.slider-navigation > span[data-direction="right"]'),
    el.querySelector<HTMLElement>('.slider-navigation > span[data-direction="left"]')
  )
}
